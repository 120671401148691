import styles from "../styles/home.module.scss";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import BloodStationsNeed from "../components/mainPageBlocks/bloodStationsNeed";
import TopDonor from "../components/topDonor/topDonor";
import InterestingForYou from "../components/mainPageBlocks/interestingForYou";
import EventsBlock from "../components/mainPageBlocks/eventsBlock";
import ResourseStore from "../stores/ResourseStore";
import BonusesBlock from "../components/mainPageBlocks/bonusesBlock";
import BlockContainer from "../components/mainPageBlocks/blockContainer";
import SelectedCityStore from "../stores/SelectedCityStore";
import BannersBlock from "../components/mainPageBlocks/bannersBlock";
import DonatorAdvices from "../components/mainPageBlocks/donatorAdvices";
import { DonorNeedsBlock } from "../components/DonorNeeds/DonorNeeds";
import SocialBlock from "../components/mainPage/SocialBlock/SocialBlock";
import AuthStore from "../stores/AuthStore";
import Head from "next/dist/next-server/lib/head";
import MainDonationForm from "../components/donationForm/mainDonationForm";

@inject("authStore")
@inject("citiesStore")
@inject("mainPageStore")
@inject("selectedCityStore")
@observer
export default class Home extends Component {
  props: {
    authStore?: AuthStore;
    mainPageStore?: ResourseStore;
    selectedCityStore?: SelectedCityStore;
  };
  state: {
    mainPageInfo: {
      msk_avatars:
        | {
            original?: string;
            large?: string;
            medium?: string;
            small?: string;
          }[]
        | undefined;
      not_msk_avatars:
        | {
            original?: string;
            large?: string;
            medium?: string;
            small?: string;
          }[]
        | undefined;
      moscow_users: number | undefined;
      total_users: number | undefined;
      vk_participant: number | undefined;
      inst_participant: number | undefined;
    } | null;
    loader: boolean;
    error: boolean;
  };

  responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 800 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      mainPageInfo: null,
      loader: true,
      error: false,
    };
  }

  componentDidMount() {
    this.props.mainPageStore
      .load()
      .then((response) => {
        this.setState({
          mainPageInfo: response.records[0]?.data,
          loader: false,
        });
      })
      .catch(() => {
        this.setState({
          loader: false,
          error: true,
        });
      });
  }

  toOther = () => {
    this.props.selectedCityStore.setCity("Other");
    location.reload();
  };

  render() {
    const container = this.props.selectedCityStore.isMoscow
      ? styles.HomeMainBlockContainerWM
      : styles.HomeMainBlockContainer;
    return (
      <>
      <Head>
        <script src="https://widget.cloudpayments.ru/bundles/cloudpayments.js"></script>
      </Head>
      <div className={"container " + container} style={{ zIndex: 0 }}>
        {this.props.selectedCityStore.isMoscow && (
          <title>Moscow DonorSearch</title>
        )}
        {!this.props.selectedCityStore.isMoscow && <title>DonorSearch</title>}
        <BannersBlock />
        <DonatorAdvices />
        <BloodStationsNeed />
        <DonorNeedsBlock main_page />
        <EventsBlock currentUser={this.props.authStore.user} />
        <TopDonor />
        <BonusesBlock />
        <InterestingForYou />
        {/*<WaitingDonorNow />*/}
        {/* {this.props.selectedCityStore.isMoscow && (
          <BlockContainer
            title={"Сообщество доноров Москвы"}
            linkText={"smth"}
            isLink={false}
          >
            <iframe
              className={styles.Video}
              src="https://www.youtube.com/embed/9YsypMGOkm4"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </BlockContainer>
        )} */}
        <SocialBlock
          vk_participant={this.state.mainPageInfo?.vk_participant}
          inst_participant={this.state.mainPageInfo?.inst_participant}
        />
        {this.props.selectedCityStore.isTatar && (
          <div className={styles.MoscowAdditionalText}>
            <span>
              DonorSearch проводит мероприятия для доноров в Республике Татарстан в качестве победителя конкурса Грантов ПАО «Татнефть»
            </span>
          </div>
        )}
        {typeof window !== "undefined" && window.location.hostname == "moscow.donorsearch.org" && (
          <div className={styles.MoscowAdditionalText}>
            <span>
            Проект Moscow.DonorSearch.org реализован АНО "Международный Центр профессиональных коммуникаций "РАШИ" и АНО "Донор-Серч" за счет средств гранта на реализацию проектов в социальной сфере конкурса «Москва – добрый город» при поддержке Департамента труда и социальной защиты населения г.Москвы
            </span>
          </div>
        )}

        {/* <MainDonationForm /> */}
      </div>
      </>
    );
  }
}
